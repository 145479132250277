

































































import Vue from 'vue';
import AppMain from '@/components/AppMain.vue';
import AppForm from '@/components/AppForm.vue';
import AppListExpense from '@/components/AppListExpense.vue';
import AppListItem from '@/components/AppListItem.vue';
import AppListItemPhotos from '@/components/AppListItemPhotos.vue';
import EXPENSE from '@/graphql/queries/Expense.graphql';
import EXPENSE_DELETE from '@/graphql/mutations/ExpenseDelete.graphql';
import EXPENSE_REVIEW from '@/graphql/mutations/ExpenseReview.graphql';
import { required } from 'vuelidate/lib/validators';
import validationMessages from '@/utils/validationMessages';
import { CODES, EXPENSE_STATUSES } from '@/utils/constants';
import {
  Expense,
  ExpenseDeleteMutationVariables,
  ExpenseQueryVariables,
} from '@/types/schema';
import { ApolloQueryResult } from 'apollo-client';
import { GraphQLError } from 'graphql';

export default Vue.extend({
  name: 'AdminExpensesDetails',
  components: {
    AppMain,
    AppForm,
    AppListExpense,
    AppListItem,
    AppListItemPhotos,
  },
  data() {
    return {
      loading: false,
      errors: [] as GraphQLError[],
      form: {
        status: '',
      },
      expense: {} as Expense,
    };
  },
  computed: {
    isPending(): boolean {
      return this.expense.status === EXPENSE_STATUSES.PENDING;
    },
    expenseStatuses(): string[] {
      return Object.values(EXPENSE_STATUSES);
    },
  },
  validations() {
    return {
      form: {
        status: { required },
      },
    };
  },
  methods: {
    validationMessages,
    async deleteExpense() {
      if (!confirm("Are you sure? This action can't be undone.")) return;

      try {
        this.loading = true;
        this.errors = [];
        await this.$apollo.mutate({
          mutation: EXPENSE_DELETE,
          variables: {
            input: {
              id: this.expense.id,
            },
          } as ExpenseDeleteMutationVariables,
        });
        this.$notify({
          text: 'Expense deleted!',
          type: 'success',
          duration: 6000,
        });
        this.$router.replace(
          this.$route.meta.backTo || { name: 'admin-expenses' }
        );
      } catch ({ graphQLErrors }) {
        this.errors = graphQLErrors;
      } finally {
        this.loading = false;
      }
    },
    async saveExpense() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      try {
        this.loading = true;
        this.errors = [];
        await this.$apollo.mutate({
          mutation: EXPENSE_REVIEW,
          variables: {
            input: {
              id: this.expense.id,
              status: this.form.status,
            },
          },
        });
        this.$notify({
          text: 'Expense updated!',
          type: 'success',
          duration: 6000,
        });
        this.$router.replace(
          this.$route.meta.backTo || { name: 'admin-expenses' }
        );
      } catch ({ graphQLErrors }) {
        this.errors = graphQLErrors;
      } finally {
        this.loading = false;
      }
    },
    fillForm(expense: Expense) {
      if (!expense) return;
      this.form = {
        status: expense.status,
      };
    },
  },
  apollo: {
    expense: {
      query: EXPENSE,
      variables(): ExpenseQueryVariables {
        return {
          id: this.$route.params.id,
        };
      },
      result({ data }: ApolloQueryResult<{ expense: Expense }>) {
        if (data) this.fillForm(data.expense);
      },
      error({ graphQLErrors }) {
        const [gqlError] = graphQLErrors;
        if (gqlError?.extensions?.code === CODES.NOT_FOUND) {
          this.$router.replace({ name: 'admin-expenses' });
        }
      },
      fetchPolicy: 'cache-and-network',
    },
  },
});
