


























import Vue from 'vue';
import AppListItem from '@/components/AppListItem.vue';
import formatCurrency from '@/utils/formatCurrency';
import formatDate from '@/utils/formatDate';
import expenseStatusColor from '@/utils/expenseStatusColor';
import { Expense, User } from '@/types/schema';

export default Vue.extend({
  name: 'AppListExpense',
  components: {
    AppListItem,
  },
  inheritAttrs: false,
  props: {
    expense: {
      type: Object as () => Expense,
      default: Object as () => Expense,
    },
    user: {
      type: Object as () => User,
      default: Object as () => User,
    },
  },
  computed: {
    userName(): string {
      if (!this.user || !this.user.fullName) return '';
      return this.user.fullName || '';
    },
    formattedDate(): string {
      return formatDate(this.expense.date);
    },
    formattedAmount(): string {
      return formatCurrency(this.expense.amount);
    },
    statusColor(): string {
      return expenseStatusColor(this.expense.status);
    },
  },
});
