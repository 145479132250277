import { EXPENSE_STATUSES } from '@/utils/constants';
import { ExpenseStatus } from '@/types/schema';

export default (status: ExpenseStatus): string => {
  const color = {
    [EXPENSE_STATUSES.PENDING]: 'warning',
    [EXPENSE_STATUSES.APPROVED]: 'success',
    [EXPENSE_STATUSES.DENIED]: 'error',
  };
  return color[status] || '';
};
